@font-face {
  font-family: "Crops";
  src: local("Crops"), url(./fonts/crops/Crops-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Crops";
  font-weight: 900;
  src: local("Crops"), url(./fonts/crops/Crops-Bold.ttf) format("truetype");
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  margin: 0;
  font-family: Crops, serif;
  cursor: url("./images/pointer.svg"), auto;
}





a:hover {
  cursor: url("./images/cursor.svg"), pointer;
}

/* BurgerButton */

.hamburger-menu {
  position: absolute;
  z-index: 5;
  top: 5px;
  left: 47px;
  font-size: 12px !important;
  cursor: url("./images/cursor.svg"), pointer;
}
#toggle {
  display: none;
}
.hamburger {
  position: absolute;
  top: 5em;
  right: 5%;
  margin-left: -2em;
  margin-top: -45px;
  width: 2em;
  height: 45px;
  z-index: 5;
  cursor: url("./images/cursor.svg"), pointer;
}

.hamburger div {
  position: relative;
  width: 43px;
  height: 4px;
  border-radius: 3px;
  background-color: #357fae;
  margin-top: 8px;
  transition: all 0.3s ease-in-out;
}

#toggle:checked + .hamburger .top-bun {
  transform: rotate(-45deg);
  margin-top: 25px;
}
#toggle:checked + .hamburger .bottom-bun {
  opacity: 0;
  transform: rotate(45deg);
}
#toggle:checked + .hamburger .meat {
  transform: rotate(45deg);
  margin-top: -4px;
}

#toggle:checked ~ .menu__box {
  left: 0 !important;
  z-index: 4;
}

.menu__box {
  display: block;
  position: fixed;
  top: 0;
  left: -100%;
  width: 200px;
  height: 100%;
  margin: 0;
  padding: 80px 0;
  list-style: none;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  transition-duration: 0.25s;
  background-color: #00022ddb;
}
.menu__item {
  background-color: transparent;
  border: none;
  display: block;
  padding: 12px 24px;
  color: #9d9c9c;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  transition-duration: 0.25s;
  width: 100%;
  text-align: left;
  padding: 25px;
  cursor: url("./images/cursor.svg"), pointer;
}

.menu__item:hover {
  background-color: #171651;
}

/* NavigationButton */

.to-top {
  color: rgb(0, 46, 78);
  padding-top: 1.8em;
  display: inline-block; /* or block */
  position: relative;
  border-color: rgb(0, 46, 78);
  text-decoration: none;
  transition: all 0.6s ease-out;
  text-align: center;
  cursor: url("./images/cursor.svg"), pointer;
}
.to-top:before {
  content: "\2949";
  font-size: 3em;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -0.7em;
  border: solid 0.1em white;
  border-radius: 10em;
  width: 1.4em;
  height: 1.4em;
  line-height: 1.3em;
  border-color: inherit;
  transition: transform 0.5s ease-in;
}
.to-top:hover {
  color: #51779e;
  border-color: #51779e;
}
.to-top:hover:before {
  transform: rotate(360deg);
}
/*  BulletButton */

.object {
  height: 170px;
  cursor: url("./images/cursor.svg"), pointer;
}
.wave {
  width: 210px;
  height: 210px;
  background: #51779e;
  opacity: 0.5;
  border-radius: 50%;
  animation: zoomIn;
  animation-duration: 3s;
  position: relative;
  bottom: 189px;
  right: 25px;
  z-index: -1;
}
.object__img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 5px solid #51779e;
  border-radius: 50%;
}

.object__intro {
  cursor: url("./images/cursor.svg"), pointer;
}

.object__intro-title {
  letter-spacing: 3px;
  font-weight: 900;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.6, 0.6, 0.6);
    transform: scale3d(0.6, 0.6, 0.6);
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

/* starsBackground */

#stars {
  content: "";
  background: transparent;

  box-shadow: 1744px 122px #97979761, 134px 1321px #97979761,
    92px 859px #97979761, 235px 1045px #97979761, 366px 912px #97979761,
    491px 942px #97979761, 14px 1831px #97979761, 582px 476px #97979761,
    588px 1230px #97979761, 1520px 1343px #97979761, 1671px 203px #97979761,
    550px 341px #97979761, 1491px 549px #97979761, 558px 161px #97979761,
    896px 1823px #97979761, 999px 1463px #97979761, 1557px 636px #97979761,
    1754px 1307px #97979761, 1682px 1494px #97979761, 703px 1707px #97979761,
    1945px 1847px #97979761, 1151px 1320px #97979761, 980px 144px #97979761,
    478px 948px #97979761, 109px 1762px #97979761, 558px 255px #97979761,
    719px 1820px #97979761, 588px 748px #97979761, 1899px 174px #97979761,
    841px 1771px #97979761, 571px 1588px #97979761, 1155px 353px #97979761,
    1879px 1220px #97979761, 1782px 870px #97979761, 407px 1238px #97979761,
    1141px 63px #97979761, 1147px 1097px #97979761, 1406px 159px #97979761,
    637px 1215px #97979761, 694px 1114px #97979761, 1536px 727px #97979761,
    1708px 1512px #97979761, 1147px 880px #97979761, 684px 988px #97979761,
    140px 851px #97979761, 1565px 1788px #97979761, 1573px 889px #97979761,
    1172px 628px #97979761, 1474px 1978px #97979761, 435px 447px #97979761,
    185px 1808px #97979761, 620px 1560px #97979761, 1387px 1196px #97979761,
    138px 1854px #97979761, 137px 1499px #97979761, 1721px 1132px #97979761,
    10px 32px #97979761, 1931px 1990px #97979761, 209px 91px #97979761,
    1876px 1795px #97979761, 1130px 1551px #97979761, 284px 1848px #97979761,
    389px 1603px #97979761, 612px 1121px #97979761, 825px 1926px #97979761,
    161px 344px #97979761, 1729px 333px #97979761, 1037px 37px #97979761,
    844px 1085px #97979761, 1179px 554px #97979761, 50px 478px #97979761,
    1864px 704px #97979761, 233px 723px #97979761, 1202px 445px #97979761,
    882px 189px #97979761, 362px 735px #97979761, 924px 411px #97979761,
    902px 209px #97979761, 104px 185px #97979761, 1599px 1852px #97979761,
    1974px 944px #97979761, 438px 1164px #97979761, 401px 1533px #97979761,
    191px 1429px #97979761, 251px 1034px #97979761, 1807px 1412px #97979761,
    72px 23px #97979761, 1752px 1146px #97979761, 261px 1481px #97979761,
    548px 33px #97979761, 710px 1204px #97979761, 355px 1697px #97979761,
    581px 100px #97979761, 318px 1146px #97979761, 929px 79px #97979761,
    999px 347px #97979761, 155px 292px #97979761, 271px 677px #97979761,
    920px 1596px #97979761, 1736px 184px #97979761, 1022px 1790px #97979761,
    1465px 1762px #97979761, 820px 526px #97979761, 175px 37px #97979761,
    440px 746px #97979761, 681px 1879px #97979761, 690px 1135px #97979761,
    1960px 1453px #97979761, 422px 856px #97979761, 1217px 1232px #97979761,
    1015px 1695px #97979761, 1933px 492px #97979761, 272px 448px #97979761,
    1578px 1487px #97979761, 437px 874px #97979761, 947px 838px #97979761,
    1339px 867px #97979761, 1484px 773px #97979761, 764px 66px #97979761,
    418px 707px #97979761, 192px 1909px #97979761, 1629px 215px #97979761,
    171px 260px #97979761, 1180px 220px #97979761, 488px 857px #97979761,
    593px 493px #97979761, 1794px 886px #97979761, 1673px 1977px #97979761,
    905px 1193px #97979761, 1372px 1843px #97979761, 1605px 908px #97979761,
    659px 181px #97979761, 700px 1758px #97979761, 812px 1200px #97979761,
    1800px 1440px #97979761, 1858px 212px #97979761, 628px 1026px #97979761,
    1825px 1556px #97979761, 1641px 1750px #97979761, 1195px 1086px #97979761,
    1465px 558px #97979761, 1634px 436px #97979761, 1354px 1831px #97979761,
    1212px 1485px #97979761, 1491px 994px #97979761, 604px 1279px #97979761,
    413px 1131px #97979761, 1677px 1086px #97979761, 841px 47px #97979761,
    146px 489px #97979761, 117px 1195px #97979761, 67px 47px #97979761,
    883px 258px #97979761, 1801px 1294px #97979761, 594px 1870px #97979761,
    649px 531px #97979761, 721px 132px #97979761, 975px 1616px #97979761,
    716px 94px #97979761, 295px 791px #97979761, 174px 1814px #97979761,
    1130px 298px #97979761, 1747px 1835px #97979761, 605px 1521px #97979761,
    78px 743px #97979761, 1685px 311px #97979761, 804px 341px #97979761,
    1440px 852px #97979761, 1607px 1692px #97979761, 698px 1112px #97979761,
    1153px 1608px #97979761, 539px 999px #97979761, 262px 353px #97979761,
    457px 1246px #97979761, 1858px 998px #97979761, 364px 431px #97979761,
    1907px 912px #97979761, 541px 916px #97979761, 168px 1384px #97979761,
    357px 1321px #97979761, 1859px 1866px #97979761, 1001px 909px #97979761,
    842px 1663px #97979761, 369px 1176px #97979761, 932px 1372px #97979761,
    1606px 732px #97979761, 1844px 857px #97979761, 974px 1588px #97979761,
    804px 1139px #97979761, 65px 1213px #97979761, 1066px 863px #97979761,
    1991px 1734px #97979761, 300px 738px #97979761, 1260px 1141px #97979761,
    83px 260px #97979761, 1219px 167px #97979761, 613px 990px #97979761,
    873px 81px #97979761, 362px 235px #97979761, 373px 372px #97979761,
    80px 247px #97979761, 902px 1141px #97979761, 294px 464px #97979761,
    766px 1925px #97979761, 1151px 1305px #97979761, 1250px 1593px #97979761,
    1289px 119px #97979761, 1525px 1505px #97979761, 234px 1306px #97979761,
    571px 858px #97979761, 571px 996px #97979761, 766px 1342px #97979761,
    1371px 716px #97979761, 711px 1939px #97979761, 904px 1797px #97979761,
    424px 1710px #97979761, 762px 1614px #97979761, 1389px 1290px #97979761,
    905px 689px #97979761, 352px 38px #97979761, 2000px 1317px #97979761,
    597px 864px #97979761, 824px 711px #97979761, 966px 1060px #97979761,
    958px 992px #97979761, 1121px 324px #97979761, 1624px 688px #97979761,
    1737px 702px #97979761, 1566px 1344px #97979761, 697px 368px #97979761,
    291px 1126px #97979761, 1732px 50px #97979761, 712px 1941px #97979761,
    1257px 372px #97979761, 317px 1462px #97979761, 222px 309px #97979761,
    1139px 647px #97979761, 170px 878px #97979761, 1272px 1511px #97979761,
    1009px 1912px #97979761, 1875px 1105px #97979761, 1968px 1370px #97979761,
    1193px 687px #97979761, 1498px 611px #97979761, 247px 1993px #97979761,
    1686px 1611px #97979761, 937px 340px #97979761, 114px 1423px #97979761,
    496px 1191px #97979761, 110px 1284px #97979761, 876px 687px #97979761,
    1173px 214px #97979761, 210px 628px #97979761, 302px 1173px #97979761,
    1318px 939px #97979761, 1665px 1310px #97979761, 66px 338px #97979761,
    1355px 615px #97979761, 1139px 1672px #97979761, 91px 1000px #97979761,
    112px 422px #97979761, 89px 503px #97979761, 97px 1599px #97979761,
    1031px 1955px #97979761, 1755px 1532px #97979761, 1764px 1930px #97979761,
    1822px 1751px #97979761, 133px 401px #97979761, 718px 85px #97979761,
    1797px 78px #97979761, 520px 1725px #97979761, 226px 406px #97979761,
    1561px 1574px #97979761, 340px 1602px #97979761, 718px 976px #97979761,
    1823px 570px #97979761, 1298px 484px #97979761, 1023px 1903px #97979761,
    1266px 1334px #97979761, 763px 877px #97979761, 223px 631px #97979761,
    1761px 1293px #97979761, 878px 1px #97979761, 973px 696px #97979761,
    975px 1841px #97979761, 1276px 302px #97979761, 1399px 1324px #97979761,
    1706px 872px #97979761, 341px 1960px #97979761, 1384px 1655px #97979761,
    1897px 767px #97979761, 68px 405px #97979761, 705px 310px #97979761,
    194px 103px #97979761, 1432px 368px #97979761, 1262px 1682px #97979761,
    1933px 1526px #97979761, 263px 655px #97979761, 1031px 1031px #97979761,
    1557px 788px #97979761, 1558px 1360px #97979761, 812px 421px #97979761,
    1951px 734px #97979761, 1567px 498px #97979761, 1832px 1229px #97979761,
    913px 164px #97979761, 1907px 1273px #97979761, 533px 749px #97979761,
    1235px 951px #97979761, 645px 1959px #97979761, 92px 1570px #97979761,
    1169px 1434px #97979761, 118px 1223px #97979761, 583px 957px #97979761,
    1245px 316px #97979761, 527px 863px #97979761, 372px 1007px #97979761,
    244px 1251px #97979761, 314px 494px #97979761, 1842px 1463px #97979761,
    1315px 730px #97979761, 1655px 1366px #97979761, 482px 979px #97979761,
    340px 1808px #97979761, 256px 578px #97979761, 858px 1792px #97979761,
    1965px 403px #97979761, 454px 253px #97979761, 1664px 698px #97979761,
    1974px 750px #97979761, 262px 1801px #97979761, 1652px 1076px #97979761,
    992px 711px #97979761, 1792px 1323px #97979761, 700px 1748px #97979761,
    855px 966px #97979761, 1833px 1747px #97979761, 402px 1272px #97979761,
    1391px 1039px #97979761, 1395px 1538px #97979761, 397px 1972px #97979761,
    744px 1403px #97979761, 1784px 1046px #97979761, 111px 198px #97979761,
    1827px 473px #97979761, 1347px 1891px #97979761, 1238px 1081px #97979761,
    189px 908px #97979761, 1513px 542px #97979761, 673px 981px #97979761,
    720px 1184px #97979761, 373px 792px #97979761, 1470px 1631px #97979761,
    16px 822px #97979761, 1935px 829px #97979761, 364px 613px #97979761,
    223px 899px #97979761, 1233px 874px #97979761, 1441px 1834px #97979761,
    719px 894px #97979761, 76px 1610px #97979761, 296px 685px #97979761,
    572px 1637px #97979761, 349px 114px #97979761, 1670px 1802px #97979761,
    979px 928px #97979761, 1589px 574px #97979761, 756px 194px #97979761,
    616px 507px #97979761, 271px 186px #97979761, 198px 360px #97979761,
    1916px 418px #97979761, 721px 667px #97979761, 1937px 1908px #97979761,
    17px 556px #97979761, 345px 443px #97979761, 718px 760px #97979761,
    1922px 310px #97979761, 1185px 887px #97979761, 1745px 1848px #97979761,
    909px 847px #97979761, 1170px 134px #97979761, 1773px 1133px #97979761,
    1099px 233px #97979761, 1122px 1524px #97979761, 866px 907px #97979761,
    398px 213px #97979761, 1287px 886px #97979761, 1194px 149px #97979761,
    1876px 1667px #97979761, 332px 1658px #97979761, 146px 1253px #97979761,
    958px 211px #97979761, 643px 47px #97979761, 1213px 1339px #97979761,
    1637px 1939px #97979761, 1601px 355px #97979761, 256px 1982px #97979761,
    989px 1609px #97979761, 1179px 701px #97979761, 685px 1463px #97979761,
    1910px 465px #97979761, 764px 1930px #97979761, 1856px 433px #97979761,
    720px 367px #97979761, 1821px 416px #97979761, 48px 1114px #97979761,
    716px 489px #97979761, 174px 1972px #97979761, 115px 1373px #97979761,
    1487px 141px #97979761, 1919px 1362px #97979761, 1662px 859px #97979761,
    1168px 490px #97979761, 706px 1820px #97979761, 588px 318px #97979761,
    107px 778px #97979761, 621px 1667px #97979761, 1331px 1996px #97979761,
    1404px 1154px #97979761, 860px 1019px #97979761, 1385px 1883px #97979761,
    1066px 1951px #97979761, 752px 444px #97979761, 955px 1031px #97979761,
    1256px 1899px #97979761, 961px 1455px #97979761, 1101px 84px #97979761,
    890px 335px #97979761, 798px 40px #97979761, 138px 54px #97979761,
    1934px 252px #97979761, 429px 324px #97979761, 1645px 1522px #97979761,
    218px 737px #97979761, 1625px 531px #97979761, 605px 1290px #97979761,
    1867px 648px #97979761, 82px 1137px #97979761, 231px 1423px #97979761,
    471px 944px #97979761, 1363px 1752px #97979761, 482px 1364px #97979761,
    1305px 627px #97979761, 1065px 891px #97979761, 217px 1841px #97979761,
    1843px 1501px #97979761, 1681px 734px #97979761, 185px 856px #97979761,
    609px 1094px #97979761, 49px 1657px #97979761, 783px 594px #97979761,
    1018px 1123px #97979761, 644px 1149px #97979761, 739px 1262px #97979761,
    1439px 1187px #97979761, 1789px 1507px #97979761, 941px 791px #97979761,
    1319px 1308px #97979761, 498px 626px #97979761, 1594px 858px #97979761,
    783px 71px #97979761, 576px 1976px #97979761, 1625px 696px #97979761,
    1821px 352px #97979761, 1039px 885px #97979761, 208px 430px #97979761,
    124px 198px #97979761, 1964px 686px #97979761, 1440px 1952px #97979761,
    231px 1435px #97979761, 1846px 853px #97979761, 1174px 536px #97979761,
    1228px 310px #97979761, 1380px 699px #97979761, 1116px 169px #97979761,
    1416px 1007px #97979761, 178px 192px #97979761, 1302px 1903px #97979761,
    1892px 154px #97979761, 962px 1032px #97979761, 1009px 1916px #97979761,
    1549px 1206px #97979761, 899px 968px #97979761, 902px 543px #97979761,
    1516px 542px #97979761, 965px 527px #97979761, 1594px 696px #97979761,
    856px 1719px #97979761, 1522px 248px #97979761, 1795px 283px #97979761,
    1144px 946px #97979761, 1365px 199px #97979761, 359px 1347px #97979761,
    1506px 263px #97979761, 433px 1793px #97979761, 201px 247px #97979761,
    942px 838px #97979761, 1122px 1065px #97979761, 1997px 108px #97979761,
    306px 989px #97979761, 641px 1713px #97979761, 1072px 959px #97979761,
    1874px 1079px #97979761, 1938px 269px #97979761, 891px 389px #97979761,
    22px 574px #97979761, 727px 474px #97979761, 1671px 1649px #97979761,
    98px 400px #97979761, 641px 798px #97979761, 1218px 1051px #97979761,
    907px 1485px #97979761, 1002px 1549px #97979761, 1932px 1448px #97979761,
    1132px 573px #97979761, 1222px 1147px #97979761, 1009px 451px #97979761,
    698px 1940px #97979761, 97px 1123px #97979761, 904px 751px #97979761,
    1904px 593px #97979761, 1451px 1658px #97979761, 191px 1606px #97979761,
    1346px 482px #97979761, 1935px 1086px #97979761, 1052px 224px #97979761,
    526px 1810px #97979761, 1607px 606px #97979761, 427px 1830px #97979761,
    1546px 407px #97979761, 1771px 208px #97979761, 1714px 181px #97979761,
    1332px 943px #97979761, 1663px 1094px #97979761, 1861px 1747px #97979761,
    1429px 1217px #97979761, 1733px 1915px #97979761, 925px 1179px #97979761,
    735px 781px #97979761, 1775px 1989px #97979761, 1770px 476px #97979761,
    844px 400px #97979761, 1793px 1517px #97979761, 544px 281px #97979761,
    725px 344px #97979761, 1274px 764px #97979761, 1845px 339px #97979761,
    582px 1745px #97979761, 351px 1287px #97979761, 207px 749px #97979761,
    1063px 1411px #97979761, 1246px 342px #97979761, 1538px 166px #97979761,
    770px 602px #97979761, 1214px 1971px #97979761, 113px 294px #97979761,
    1157px 101px #97979761, 1657px 517px #97979761, 637px 1693px #97979761,
    961px 1649px #97979761, 1754px 1572px #97979761, 1885px 659px #97979761,
    238px 34px #97979761, 413px 1027px #97979761, 1709px 1556px #97979761,
    460px 878px #97979761, 1597px 608px #97979761, 544px 434px #97979761,
    1258px 477px #97979761, 565px 1863px #97979761, 426px 322px #97979761,
    1917px 850px #97979761, 296px 144px #97979761, 643px 1896px #97979761,
    511px 116px #97979761, 359px 847px #97979761, 702px 1133px #97979761,
    857px 1371px #97979761, 559px 1758px #97979761, 1615px 1223px #97979761,
    1927px 1736px #97979761, 704px 941px #97979761, 1932px 942px #97979761,
    1462px 335px #97979761, 154px 1915px #97979761, 1387px 918px #97979761,
    1512px 1041px #97979761, 1094px 1334px #97979761, 1678px 865px #97979761,
    1960px 431px #97979761, 666px 1258px #97979761, 422px 1215px #97979761,
    345px 802px #97979761, 1207px 647px #97979761, 1701px 374px #97979761,
    1440px 1925px #97979761, 1536px 1163px #97979761, 905px 155px #97979761,
    843px 1036px #97979761, 660px 823px #97979761, 1329px 661px #97979761,
    1867px 899px #97979761, 1022px 514px #97979761, 1062px 1570px #97979761,
    888px 1786px #97979761, 699px 1738px #97979761, 1831px 1712px #97979761,
    434px 18px #97979761, 227px 205px #97979761, 520px 1279px #97979761,
    1329px 764px #97979761, 1824px 47px #97979761, 112px 266px #97979761,
    830px 1140px #97979761, 478px 637px #97979761, 634px 850px #97979761,
    711px 873px #97979761, 987px 249px #97979761, 1454px 909px #97979761,
    699px 922px #97979761, 1458px 1142px #97979761, 701px 1893px #97979761,
    134px 384px #97979761, 904px 354px #97979761, 1913px 1229px #97979761,
    1606px 1336px #97979761, 976px 1776px #97979761, 1726px 605px #97979761,
    1244px 590px #97979761, 1351px 526px #97979761, 1997px 130px #97979761,
    1137px 1134px #97979761, 1293px 283px #97979761, 977px 1608px #97979761,
    422px 1252px #97979761, 236px 1653px #97979761, 28px 1722px #97979761,
    698px 39px #97979761, 5px 434px #97979761, 1430px 1466px #97979761,
    1470px 1596px #97979761, 487px 81px #97979761, 186px 340px #97979761,
    1941px 1px #97979761, 1342px 487px #97979761, 813px 1855px #97979761,
    1445px 148px #97979761, 1965px 1428px #97979761, 1122px 1277px #97979761,
    846px 820px #97979761, 1804px 228px #97979761, 1501px 121px #97979761,
    215px 1033px #97979761, 244px 426px #97979761, 976px 453px #97979761,
    566px 953px #97979761, 1772px 213px #97979761, 1323px 1778px #97979761,
    1350px 817px #97979761, 803px 913px #97979761, 1395px 1359px #97979761,
    1995px 119px #97979761, 579px 1963px #97979761, 862px 1621px #97979761,
    1718px 825px #97979761, 823px 27px #97979761, 1252px 41px #97979761,
    1562px 835px #97979761, 1730px 633px #97979761, 1723px 1939px #97979761,
    524px 1824px #97979761, 1921px 1450px #97979761, 1174px 1062px #97979761,
    1008px 1932px #97979761, 467px 334px #97979761, 80px 818px #97979761,
    1266px 1185px #97979761, 1589px 807px #97979761, 1221px 320px #97979761,
    1724px 852px #97979761, 1194px 286px #97979761, 800px 1835px #97979761,
    1958px 728px #97979761, 1271px 376px #97979761, 43px 1654px #97979761,
    77px 803px #97979761, 1399px 197px #97979761, 591px 1743px #97979761,
    652px 1925px #97979761, 107px 1939px #97979761, 1110px 1708px #97979761,
    797px 1480px #97979761, 1516px 684px #97979761, 559px 1733px #97979761,
    1265px 718px #97979761, 1263px 54px #97979761, 693px 113px #97979761,
    1456px 1459px #97979761, 1527px 1324px #97979761;
  animation: animStar 20s linear infinite;
}
#stars:after {
  content: " ";
  position: absolute;
  top: 2000px;

  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1744px 122px #97979761, 134px 1321px #97979761,
    92px 859px #97979761, 235px 1045px #97979761, 366px 912px #97979761,
    491px 942px #97979761, 14px 1831px #97979761, 582px 476px #97979761,
    588px 1230px #97979761, 1520px 1343px #97979761, 1671px 203px #97979761,
    550px 341px #97979761, 1491px 549px #97979761, 558px 161px #97979761,
    896px 1823px #97979761, 999px 1463px #97979761, 1557px 636px #97979761,
    1754px 1307px #97979761, 1682px 1494px #97979761, 703px 1707px #97979761,
    1945px 1847px #97979761, 1151px 1320px #97979761, 980px 144px #97979761,
    478px 948px #97979761, 109px 1762px #97979761, 558px 255px #97979761,
    719px 1820px #97979761, 588px 748px #97979761, 1899px 174px #97979761,
    841px 1771px #97979761, 571px 1588px #97979761, 1155px 353px #97979761,
    1879px 1220px #97979761, 1782px 870px #97979761, 407px 1238px #97979761,
    1141px 63px #97979761, 1147px 1097px #97979761, 1406px 159px #97979761,
    637px 1215px #97979761, 694px 1114px #97979761, 1536px 727px #97979761,
    1708px 1512px #97979761, 1147px 880px #97979761, 684px 988px #97979761,
    140px 851px #97979761, 1565px 1788px #97979761, 1573px 889px #97979761,
    1172px 628px #97979761, 1474px 1978px #97979761, 435px 447px #97979761,
    185px 1808px #97979761, 620px 1560px #97979761, 1387px 1196px #97979761,
    138px 1854px #97979761, 137px 1499px #97979761, 1721px 1132px #97979761,
    10px 32px #97979761, 1931px 1990px #97979761, 209px 91px #97979761,
    1876px 1795px #97979761, 1130px 1551px #97979761, 284px 1848px #97979761,
    389px 1603px #97979761, 612px 1121px #97979761, 825px 1926px #97979761,
    161px 344px #97979761, 1729px 333px #97979761, 1037px 37px #97979761,
    844px 1085px #97979761, 1179px 554px #97979761, 50px 478px #97979761,
    1864px 704px #97979761, 233px 723px #97979761, 1202px 445px #97979761,
    882px 189px #97979761, 362px 735px #97979761, 924px 411px #97979761,
    902px 209px #97979761, 104px 185px #97979761, 1599px 1852px #97979761,
    1974px 944px #97979761, 438px 1164px #97979761, 401px 1533px #97979761,
    191px 1429px #97979761, 251px 1034px #97979761, 1807px 1412px #97979761,
    72px 23px #97979761, 1752px 1146px #97979761, 261px 1481px #97979761,
    548px 33px #97979761, 710px 1204px #97979761, 355px 1697px #97979761,
    581px 100px #97979761, 318px 1146px #97979761, 929px 79px #97979761,
    999px 347px #97979761, 155px 292px #97979761, 271px 677px #97979761,
    920px 1596px #97979761, 1736px 184px #97979761, 1022px 1790px #97979761,
    1465px 1762px #97979761, 820px 526px #97979761, 175px 37px #97979761,
    440px 746px #97979761, 681px 1879px #97979761, 690px 1135px #97979761,
    1960px 1453px #97979761, 422px 856px #97979761, 1217px 1232px #97979761,
    1015px 1695px #97979761, 1933px 492px #97979761, 272px 448px #97979761,
    1578px 1487px #97979761, 437px 874px #97979761, 947px 838px #97979761,
    1339px 867px #97979761, 1484px 773px #97979761, 764px 66px #97979761,
    418px 707px #97979761, 192px 1909px #97979761, 1629px 215px #97979761,
    171px 260px #97979761, 1180px 220px #97979761, 488px 857px #97979761,
    593px 493px #97979761, 1794px 886px #97979761, 1673px 1977px #97979761,
    905px 1193px #97979761, 1372px 1843px #97979761, 1605px 908px #97979761,
    659px 181px #97979761, 700px 1758px #97979761, 812px 1200px #97979761,
    1800px 1440px #97979761, 1858px 212px #97979761, 628px 1026px #97979761,
    1825px 1556px #97979761, 1641px 1750px #97979761, 1195px 1086px #97979761,
    1465px 558px #97979761, 1634px 436px #97979761, 1354px 1831px #97979761,
    1212px 1485px #97979761, 1491px 994px #97979761, 604px 1279px #97979761,
    413px 1131px #97979761, 1677px 1086px #97979761, 841px 47px #97979761,
    146px 489px #97979761, 117px 1195px #97979761, 67px 47px #97979761,
    883px 258px #97979761, 1801px 1294px #97979761, 594px 1870px #97979761,
    649px 531px #97979761, 721px 132px #97979761, 975px 1616px #97979761,
    716px 94px #97979761, 295px 791px #97979761, 174px 1814px #97979761,
    1130px 298px #97979761, 1747px 1835px #97979761, 605px 1521px #97979761,
    78px 743px #97979761, 1685px 311px #97979761, 804px 341px #97979761,
    1440px 852px #97979761, 1607px 1692px #97979761, 698px 1112px #97979761,
    1153px 1608px #97979761, 539px 999px #97979761, 262px 353px #97979761,
    457px 1246px #97979761, 1858px 998px #97979761, 364px 431px #97979761,
    1907px 912px #97979761, 541px 916px #97979761, 168px 1384px #97979761,
    357px 1321px #97979761, 1859px 1866px #97979761, 1001px 909px #97979761,
    842px 1663px #97979761, 369px 1176px #97979761, 932px 1372px #97979761,
    1606px 732px #97979761, 1844px 857px #97979761, 974px 1588px #97979761,
    804px 1139px #97979761, 65px 1213px #97979761, 1066px 863px #97979761,
    1991px 1734px #97979761, 300px 738px #97979761, 1260px 1141px #97979761,
    83px 260px #97979761, 1219px 167px #97979761, 613px 990px #97979761,
    873px 81px #97979761, 362px 235px #97979761, 373px 372px #97979761,
    80px 247px #97979761, 902px 1141px #97979761, 294px 464px #97979761,
    766px 1925px #97979761, 1151px 1305px #97979761, 1250px 1593px #97979761,
    1289px 119px #97979761, 1525px 1505px #97979761, 234px 1306px #97979761,
    571px 858px #97979761, 571px 996px #97979761, 766px 1342px #97979761,
    1371px 716px #97979761, 711px 1939px #97979761, 904px 1797px #97979761,
    424px 1710px #97979761, 762px 1614px #97979761, 1389px 1290px #97979761,
    905px 689px #97979761, 352px 38px #97979761, 2000px 1317px #97979761,
    597px 864px #97979761, 824px 711px #97979761, 966px 1060px #97979761,
    958px 992px #97979761, 1121px 324px #97979761, 1624px 688px #97979761,
    1737px 702px #97979761, 1566px 1344px #97979761, 697px 368px #97979761,
    291px 1126px #97979761, 1732px 50px #97979761, 712px 1941px #97979761,
    1257px 372px #97979761, 317px 1462px #97979761, 222px 309px #97979761,
    1139px 647px #97979761, 170px 878px #97979761, 1272px 1511px #97979761,
    1009px 1912px #97979761, 1875px 1105px #97979761, 1968px 1370px #97979761,
    1193px 687px #97979761, 1498px 611px #97979761, 247px 1993px #97979761,
    1686px 1611px #97979761, 937px 340px #97979761, 114px 1423px #97979761,
    496px 1191px #97979761, 110px 1284px #97979761, 876px 687px #97979761,
    1173px 214px #97979761, 210px 628px #97979761, 302px 1173px #97979761,
    1318px 939px #97979761, 1665px 1310px #97979761, 66px 338px #97979761,
    1355px 615px #97979761, 1139px 1672px #97979761, 91px 1000px #97979761,
    112px 422px #97979761, 89px 503px #97979761, 97px 1599px #97979761,
    1031px 1955px #97979761, 1755px 1532px #97979761, 1764px 1930px #97979761,
    1822px 1751px #97979761, 133px 401px #97979761, 718px 85px #97979761,
    1797px 78px #97979761, 520px 1725px #97979761, 226px 406px #97979761,
    1561px 1574px #97979761, 340px 1602px #97979761, 718px 976px #97979761,
    1823px 570px #97979761, 1298px 484px #97979761, 1023px 1903px #97979761,
    1266px 1334px #97979761, 763px 877px #97979761, 223px 631px #97979761,
    1761px 1293px #97979761, 878px 1px #97979761, 973px 696px #97979761,
    975px 1841px #97979761, 1276px 302px #97979761, 1399px 1324px #97979761,
    1706px 872px #97979761, 341px 1960px #97979761, 1384px 1655px #97979761,
    1897px 767px #97979761, 68px 405px #97979761, 705px 310px #97979761,
    194px 103px #97979761, 1432px 368px #97979761, 1262px 1682px #97979761,
    1933px 1526px #97979761, 263px 655px #97979761, 1031px 1031px #97979761,
    1557px 788px #97979761, 1558px 1360px #97979761, 812px 421px #97979761,
    1951px 734px #97979761, 1567px 498px #97979761, 1832px 1229px #97979761,
    913px 164px #97979761, 1907px 1273px #97979761, 533px 749px #97979761,
    1235px 951px #97979761, 645px 1959px #97979761, 92px 1570px #97979761,
    1169px 1434px #97979761, 118px 1223px #97979761, 583px 957px #97979761,
    1245px 316px #97979761, 527px 863px #97979761, 372px 1007px #97979761,
    244px 1251px #97979761, 314px 494px #97979761, 1842px 1463px #97979761,
    1315px 730px #97979761, 1655px 1366px #97979761, 482px 979px #97979761,
    340px 1808px #97979761, 256px 578px #97979761, 858px 1792px #97979761,
    1965px 403px #97979761, 454px 253px #97979761, 1664px 698px #97979761,
    1974px 750px #97979761, 262px 1801px #97979761, 1652px 1076px #97979761,
    992px 711px #97979761, 1792px 1323px #97979761, 700px 1748px #97979761,
    855px 966px #97979761, 1833px 1747px #97979761, 402px 1272px #97979761,
    1391px 1039px #97979761, 1395px 1538px #97979761, 397px 1972px #97979761,
    744px 1403px #97979761, 1784px 1046px #97979761, 111px 198px #97979761,
    1827px 473px #97979761, 1347px 1891px #97979761, 1238px 1081px #97979761,
    189px 908px #97979761, 1513px 542px #97979761, 673px 981px #97979761,
    720px 1184px #97979761, 373px 792px #97979761, 1470px 1631px #97979761,
    16px 822px #97979761, 1935px 829px #97979761, 364px 613px #97979761,
    223px 899px #97979761, 1233px 874px #97979761, 1441px 1834px #97979761,
    719px 894px #97979761, 76px 1610px #97979761, 296px 685px #97979761,
    572px 1637px #97979761, 349px 114px #97979761, 1670px 1802px #97979761,
    979px 928px #97979761, 1589px 574px #97979761, 756px 194px #97979761,
    616px 507px #97979761, 271px 186px #97979761, 198px 360px #97979761,
    1916px 418px #97979761, 721px 667px #97979761, 1937px 1908px #97979761,
    17px 556px #97979761, 345px 443px #97979761, 718px 760px #97979761,
    1922px 310px #97979761, 1185px 887px #97979761, 1745px 1848px #97979761,
    909px 847px #97979761, 1170px 134px #97979761, 1773px 1133px #97979761,
    1099px 233px #97979761, 1122px 1524px #97979761, 866px 907px #97979761,
    398px 213px #97979761, 1287px 886px #97979761, 1194px 149px #97979761,
    1876px 1667px #97979761, 332px 1658px #97979761, 146px 1253px #97979761,
    958px 211px #97979761, 643px 47px #97979761, 1213px 1339px #97979761,
    1637px 1939px #97979761, 1601px 355px #97979761, 256px 1982px #97979761,
    989px 1609px #97979761, 1179px 701px #97979761, 685px 1463px #97979761,
    1910px 465px #97979761, 764px 1930px #97979761, 1856px 433px #97979761,
    720px 367px #97979761, 1821px 416px #97979761, 48px 1114px #97979761,
    716px 489px #97979761, 174px 1972px #97979761, 115px 1373px #97979761,
    1487px 141px #97979761, 1919px 1362px #97979761, 1662px 859px #97979761,
    1168px 490px #97979761, 706px 1820px #97979761, 588px 318px #97979761,
    107px 778px #97979761, 621px 1667px #97979761, 1331px 1996px #97979761,
    1404px 1154px #97979761, 860px 1019px #97979761, 1385px 1883px #97979761,
    1066px 1951px #97979761, 752px 444px #97979761, 955px 1031px #97979761,
    1256px 1899px #97979761, 961px 1455px #97979761, 1101px 84px #97979761,
    890px 335px #97979761, 798px 40px #97979761, 138px 54px #97979761,
    1934px 252px #97979761, 429px 324px #97979761, 1645px 1522px #97979761,
    218px 737px #97979761, 1625px 531px #97979761, 605px 1290px #97979761,
    1867px 648px #97979761, 82px 1137px #97979761, 231px 1423px #97979761,
    471px 944px #97979761, 1363px 1752px #97979761, 482px 1364px #97979761,
    1305px 627px #97979761, 1065px 891px #97979761, 217px 1841px #97979761,
    1843px 1501px #97979761, 1681px 734px #97979761, 185px 856px #97979761,
    609px 1094px #97979761, 49px 1657px #97979761, 783px 594px #97979761,
    1018px 1123px #97979761, 644px 1149px #97979761, 739px 1262px #97979761,
    1439px 1187px #97979761, 1789px 1507px #97979761, 941px 791px #97979761,
    1319px 1308px #97979761, 498px 626px #97979761, 1594px 858px #97979761,
    783px 71px #97979761, 576px 1976px #97979761, 1625px 696px #97979761,
    1821px 352px #97979761, 1039px 885px #97979761, 208px 430px #97979761,
    124px 198px #97979761, 1964px 686px #97979761, 1440px 1952px #97979761,
    231px 1435px #97979761, 1846px 853px #97979761, 1174px 536px #97979761,
    1228px 310px #97979761, 1380px 699px #97979761, 1116px 169px #97979761,
    1416px 1007px #97979761, 178px 192px #97979761, 1302px 1903px #97979761,
    1892px 154px #97979761, 962px 1032px #97979761, 1009px 1916px #97979761,
    1549px 1206px #97979761, 899px 968px #97979761, 902px 543px #97979761,
    1516px 542px #97979761, 965px 527px #97979761, 1594px 696px #97979761,
    856px 1719px #97979761, 1522px 248px #97979761, 1795px 283px #97979761,
    1144px 946px #97979761, 1365px 199px #97979761, 359px 1347px #97979761,
    1506px 263px #97979761, 433px 1793px #97979761, 201px 247px #97979761,
    942px 838px #97979761, 1122px 1065px #97979761, 1997px 108px #97979761,
    306px 989px #97979761, 641px 1713px #97979761, 1072px 959px #97979761,
    1874px 1079px #97979761, 1938px 269px #97979761, 891px 389px #97979761,
    22px 574px #97979761, 727px 474px #97979761, 1671px 1649px #97979761,
    98px 400px #97979761, 641px 798px #97979761, 1218px 1051px #97979761,
    907px 1485px #97979761, 1002px 1549px #97979761, 1932px 1448px #97979761,
    1132px 573px #97979761, 1222px 1147px #97979761, 1009px 451px #97979761,
    698px 1940px #97979761, 97px 1123px #97979761, 904px 751px #97979761,
    1904px 593px #97979761, 1451px 1658px #97979761, 191px 1606px #97979761,
    1346px 482px #97979761, 1935px 1086px #97979761, 1052px 224px #97979761,
    526px 1810px #97979761, 1607px 606px #97979761, 427px 1830px #97979761,
    1546px 407px #97979761, 1771px 208px #97979761, 1714px 181px #97979761,
    1332px 943px #97979761, 1663px 1094px #97979761, 1861px 1747px #97979761,
    1429px 1217px #97979761, 1733px 1915px #97979761, 925px 1179px #97979761,
    735px 781px #97979761, 1775px 1989px #97979761, 1770px 476px #97979761,
    844px 400px #97979761, 1793px 1517px #97979761, 544px 281px #97979761,
    725px 344px #97979761, 1274px 764px #97979761, 1845px 339px #97979761,
    582px 1745px #97979761, 351px 1287px #97979761, 207px 749px #97979761,
    1063px 1411px #97979761, 1246px 342px #97979761, 1538px 166px #97979761,
    770px 602px #97979761, 1214px 1971px #97979761, 113px 294px #97979761,
    1157px 101px #97979761, 1657px 517px #97979761, 637px 1693px #97979761,
    961px 1649px #97979761, 1754px 1572px #97979761, 1885px 659px #97979761,
    238px 34px #97979761, 413px 1027px #97979761, 1709px 1556px #97979761,
    460px 878px #97979761, 1597px 608px #97979761, 544px 434px #97979761,
    1258px 477px #97979761, 565px 1863px #97979761, 426px 322px #97979761,
    1917px 850px #97979761, 296px 144px #97979761, 643px 1896px #97979761,
    511px 116px #97979761, 359px 847px #97979761, 702px 1133px #97979761,
    857px 1371px #97979761, 559px 1758px #97979761, 1615px 1223px #97979761,
    1927px 1736px #97979761, 704px 941px #97979761, 1932px 942px #97979761,
    1462px 335px #97979761, 154px 1915px #97979761, 1387px 918px #97979761,
    1512px 1041px #97979761, 1094px 1334px #97979761, 1678px 865px #97979761,
    1960px 431px #97979761, 666px 1258px #97979761, 422px 1215px #97979761,
    345px 802px #97979761, 1207px 647px #97979761, 1701px 374px #97979761,
    1440px 1925px #97979761, 1536px 1163px #97979761, 905px 155px #97979761,
    843px 1036px #97979761, 660px 823px #97979761, 1329px 661px #97979761,
    1867px 899px #97979761, 1022px 514px #97979761, 1062px 1570px #97979761,
    888px 1786px #97979761, 699px 1738px #97979761, 1831px 1712px #97979761,
    434px 18px #97979761, 227px 205px #97979761, 520px 1279px #97979761,
    1329px 764px #97979761, 1824px 47px #97979761, 112px 266px #97979761,
    830px 1140px #97979761, 478px 637px #97979761, 634px 850px #97979761,
    711px 873px #97979761, 987px 249px #97979761, 1454px 909px #97979761,
    699px 922px #97979761, 1458px 1142px #97979761, 701px 1893px #97979761,
    134px 384px #97979761, 904px 354px #97979761, 1913px 1229px #97979761,
    1606px 1336px #97979761, 976px 1776px #97979761, 1726px 605px #97979761,
    1244px 590px #97979761, 1351px 526px #97979761, 1997px 130px #97979761,
    1137px 1134px #97979761, 1293px 283px #97979761, 977px 1608px #97979761,
    422px 1252px #97979761, 236px 1653px #97979761, 28px 1722px #97979761,
    698px 39px #97979761, 5px 434px #97979761, 1430px 1466px #97979761,
    1470px 1596px #97979761, 487px 81px #97979761, 186px 340px #97979761,
    1941px 1px #97979761, 1342px 487px #97979761, 813px 1855px #97979761,
    1445px 148px #97979761, 1965px 1428px #97979761, 1122px 1277px #97979761,
    846px 820px #97979761, 1804px 228px #97979761, 1501px 121px #97979761,
    215px 1033px #97979761, 244px 426px #97979761, 976px 453px #97979761,
    566px 953px #97979761, 1772px 213px #97979761, 1323px 1778px #97979761,
    1350px 817px #97979761, 803px 913px #97979761, 1395px 1359px #97979761,
    1995px 119px #97979761, 579px 1963px #97979761, 862px 1621px #97979761,
    1718px 825px #97979761, 823px 27px #97979761, 1252px 41px #97979761,
    1562px 835px #97979761, 1730px 633px #97979761, 1723px 1939px #97979761,
    524px 1824px #97979761, 1921px 1450px #97979761, 1174px 1062px #97979761,
    1008px 1932px #97979761, 467px 334px #97979761, 80px 818px #97979761,
    1266px 1185px #97979761, 1589px 807px #97979761, 1221px 320px #97979761,
    1724px 852px #97979761, 1194px 286px #97979761, 800px 1835px #97979761,
    1958px 728px #97979761, 1271px 376px #97979761, 43px 1654px #97979761,
    77px 803px #97979761, 1399px 197px #97979761, 591px 1743px #97979761,
    652px 1925px #97979761, 107px 1939px #97979761, 1110px 1708px #97979761,
    797px 1480px #97979761, 1516px 684px #97979761, 559px 1733px #97979761,
    1265px 718px #97979761, 1263px 54px #97979761, 693px 113px #97979761,
    1456px 1459px #97979761, 1527px 1324px #97979761;
}

#stars2 {
  width: 2px;
  height: 2px;

  background: transparent;
  box-shadow: 1907px 1575px #97979761, 893px 268px #97979761,
    1819px 666px #97979761, 366px 1985px #97979761, 1736px 1062px #97979761,
    1124px 45px #97979761, 905px 1674px #97979761, 1570px 711px #97979761,
    255px 1747px #97979761, 147px 1032px #97979761, 865px 1813px #97979761,
    1721px 1461px #97979761, 202px 724px #97979761, 1860px 812px #97979761,
    634px 1410px #97979761, 927px 1264px #97979761, 1648px 620px #97979761,
    1915px 1309px #97979761, 731px 778px #97979761, 91px 1185px #97979761,
    829px 1003px #97979761, 174px 1784px #97979761, 1094px 1874px #97979761,
    1464px 484px #97979761, 1639px 1717px #97979761, 959px 573px #97979761,
    1686px 1287px #97979761, 906px 356px #97979761, 970px 499px #97979761,
    626px 890px #97979761, 1344px 1070px #97979761, 1351px 544px #97979761,
    708px 68px #97979761, 1870px 130px #97979761, 556px 1270px #97979761,
    1432px 618px #97979761, 769px 673px #97979761, 1289px 1410px #97979761,
    1499px 1969px #97979761, 749px 1163px #97979761, 1198px 198px #97979761,
    399px 133px #97979761, 1856px 1280px #97979761, 904px 194px #97979761,
    973px 1107px #97979761, 1371px 1785px #97979761, 989px 1835px #97979761,
    1043px 1517px #97979761, 787px 916px #97979761, 1635px 1155px #97979761,
    752px 524px #97979761, 1926px 1279px #97979761, 1052px 1163px #97979761,
    719px 1963px #97979761, 786px 519px #97979761, 1440px 500px #97979761,
    156px 1265px #97979761, 1691px 429px #97979761, 1349px 1474px #97979761,
    736px 459px #97979761, 248px 1678px #97979761, 1136px 510px #97979761,
    1317px 10px #97979761, 1209px 1323px #97979761, 54px 469px #97979761,
    730px 1939px #97979761, 960px 23px #97979761, 112px 941px #97979761,
    1852px 1522px #97979761, 1993px 1972px #97979761, 265px 129px #97979761,
    222px 1897px #97979761, 1766px 1804px #97979761, 1530px 1786px #97979761,
    1045px 432px #97979761, 1916px 1980px #97979761, 309px 1780px #97979761,
    1420px 559px #97979761, 1708px 103px #97979761, 324px 1407px #97979761,
    1175px 943px #97979761, 80px 1221px #97979761, 1016px 1933px #97979761,
    318px 560px #97979761, 190px 1489px #97979761, 1095px 892px #97979761,
    1975px 1191px #97979761, 1197px 955px #97979761, 1482px 873px #97979761,
    1912px 300px #97979761, 2000px 1636px #97979761, 1473px 1808px #97979761,
    507px 192px #97979761, 924px 1300px #97979761, 1485px 1636px #97979761,
    41px 1822px #97979761, 689px 120px #97979761, 769px 273px #97979761,
    1039px 976px #97979761, 1057px 1520px #97979761, 1417px 1187px #97979761,
    512px 1507px #97979761, 519px 45px #97979761, 1207px 1687px #97979761,
    1824px 1488px #97979761, 1694px 31px #97979761, 1859px 52px #97979761,
    1368px 268px #97979761, 190px 1404px #97979761, 940px 1003px #97979761,
    167px 942px #97979761, 1641px 647px #97979761, 1051px 654px #97979761,
    233px 1640px #97979761, 554px 511px #97979761, 1705px 1866px #97979761,
    240px 1430px #97979761, 140px 668px #97979761, 648px 1617px #97979761,
    252px 101px #97979761, 676px 1774px #97979761, 889px 1698px #97979761,
    595px 853px #97979761, 249px 1768px #97979761, 1091px 1529px #97979761,
    223px 839px #97979761, 239px 699px #97979761, 1547px 356px #97979761,
    249px 1130px #97979761, 1209px 1946px #97979761, 1967px 1274px #97979761,
    430px 298px #97979761, 1305px 134px #97979761, 156px 91px #97979761,
    1712px 264px #97979761, 1256px 991px #97979761, 1887px 1844px #97979761,
    1957px 1275px #97979761, 1718px 666px #97979761, 1090px 543px #97979761,
    933px 1411px #97979761, 801px 323px #97979761, 202px 1659px #97979761,
    1117px 549px #97979761, 290px 1859px #97979761, 1380px 1516px #97979761,
    557px 1165px #97979761, 1953px 1758px #97979761, 1901px 1407px #97979761,
    1144px 347px #97979761, 1640px 271px #97979761, 1996px 43px #97979761,
    838px 488px #97979761, 1151px 1529px #97979761, 306px 460px #97979761,
    1666px 635px #97979761, 1572px 633px #97979761, 1797px 1076px #97979761,
    1727px 1119px #97979761, 474px 871px #97979761, 1491px 1398px #97979761,
    1301px 868px #97979761, 1632px 454px #97979761, 1611px 466px #97979761,
    498px 1162px #97979761, 796px 269px #97979761, 1988px 747px #97979761,
    502px 12px #97979761, 276px 1260px #97979761, 1536px 974px #97979761,
    956px 656px #97979761, 107px 701px #97979761, 965px 491px #97979761,
    1646px 247px #97979761, 1515px 921px #97979761, 1813px 519px #97979761,
    697px 143px #97979761, 591px 1452px #97979761, 1773px 1411px #97979761,
    1622px 1401px #97979761, 534px 340px #97979761, 1590px 284px #97979761,
    1464px 528px #97979761, 734px 1718px #97979761, 1929px 1683px #97979761,
    656px 480px #97979761, 833px 705px #97979761, 1567px 109px #97979761,
    1055px 330px #97979761, 1943px 1367px #97979761, 1935px 1512px #97979761,
    355px 1024px #97979761, 1894px 218px #97979761, 1703px 1772px #97979761,
    781px 1394px #97979761, 367px 42px #97979761, 100px 690px #97979761,
    1831px 458px #97979761, 1884px 1878px #97979761, 814px 1013px #97979761;
  animation: animStar 120s linear infinite;
}
#stars2:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;

  background: transparent;
  box-shadow: 1907px 1575px #97979761, 893px 268px #97979761,
    1819px 666px #97979761, 366px 1985px #97979761, 1736px 1062px #97979761,
    1124px 45px #97979761, 905px 1674px #97979761, 1570px 711px #97979761,
    255px 1747px #97979761, 147px 1032px #97979761, 865px 1813px #97979761,
    1721px 1461px #97979761, 202px 724px #97979761, 1860px 812px #97979761,
    634px 1410px #97979761, 927px 1264px #97979761, 1648px 620px #97979761,
    1915px 1309px #97979761, 731px 778px #97979761, 91px 1185px #97979761,
    829px 1003px #97979761, 174px 1784px #97979761, 1094px 1874px #97979761,
    1464px 484px #97979761, 1639px 1717px #97979761, 959px 573px #97979761,
    1686px 1287px #97979761, 906px 356px #97979761, 970px 499px #97979761,
    626px 890px #97979761, 1344px 1070px #97979761, 1351px 544px #97979761,
    708px 68px #97979761, 1870px 130px #97979761, 556px 1270px #97979761,
    1432px 618px #97979761, 769px 673px #97979761, 1289px 1410px #97979761,
    1499px 1969px #97979761, 749px 1163px #97979761, 1198px 198px #97979761,
    399px 133px #97979761, 1856px 1280px #97979761, 904px 194px #97979761,
    973px 1107px #97979761, 1371px 1785px #97979761, 989px 1835px #97979761,
    1043px 1517px #97979761, 787px 916px #97979761, 1635px 1155px #97979761,
    752px 524px #97979761, 1926px 1279px #97979761, 1052px 1163px #97979761,
    719px 1963px #97979761, 786px 519px #97979761, 1440px 500px #97979761,
    156px 1265px #97979761, 1691px 429px #97979761, 1349px 1474px #97979761,
    736px 459px #97979761, 248px 1678px #97979761, 1136px 510px #97979761,
    1317px 10px #97979761, 1209px 1323px #97979761, 54px 469px #97979761,
    730px 1939px #97979761, 960px 23px #97979761, 112px 941px #97979761,
    1852px 1522px #97979761, 1993px 1972px #97979761, 265px 129px #97979761,
    222px 1897px #97979761, 1766px 1804px #97979761, 1530px 1786px #97979761,
    1045px 432px #97979761, 1916px 1980px #97979761, 309px 1780px #97979761,
    1420px 559px #97979761, 1708px 103px #97979761, 324px 1407px #97979761,
    1175px 943px #97979761, 80px 1221px #97979761, 1016px 1933px #97979761,
    318px 560px #97979761, 190px 1489px #97979761, 1095px 892px #97979761,
    1975px 1191px #97979761, 1197px 955px #97979761, 1482px 873px #97979761,
    1912px 300px #97979761, 2000px 1636px #97979761, 1473px 1808px #97979761,
    507px 192px #97979761, 924px 1300px #97979761, 1485px 1636px #97979761,
    41px 1822px #97979761, 689px 120px #97979761, 769px 273px #97979761,
    1039px 976px #97979761, 1057px 1520px #97979761, 1417px 1187px #97979761,
    512px 1507px #97979761, 519px 45px #97979761, 1207px 1687px #97979761,
    1824px 1488px #97979761, 1694px 31px #97979761, 1859px 52px #97979761,
    1368px 268px #97979761, 190px 1404px #97979761, 940px 1003px #97979761,
    167px 942px #97979761, 1641px 647px #97979761, 1051px 654px #97979761,
    233px 1640px #97979761, 554px 511px #97979761, 1705px 1866px #97979761,
    240px 1430px #97979761, 140px 668px #97979761, 648px 1617px #97979761,
    252px 101px #97979761, 676px 1774px #97979761, 889px 1698px #97979761,
    595px 853px #97979761, 249px 1768px #97979761, 1091px 1529px #97979761,
    223px 839px #97979761, 239px 699px #97979761, 1547px 356px #97979761,
    249px 1130px #97979761, 1209px 1946px #97979761, 1967px 1274px #97979761,
    430px 298px #97979761, 1305px 134px #97979761, 156px 91px #97979761,
    1712px 264px #97979761, 1256px 991px #97979761, 1887px 1844px #97979761,
    1957px 1275px #97979761, 1718px 666px #97979761, 1090px 543px #97979761,
    933px 1411px #97979761, 801px 323px #97979761, 202px 1659px #97979761,
    1117px 549px #97979761, 290px 1859px #97979761, 1380px 1516px #97979761,
    557px 1165px #97979761, 1953px 1758px #97979761, 1901px 1407px #97979761,
    1144px 347px #97979761, 1640px 271px #97979761, 1996px 43px #97979761,
    838px 488px #97979761, 1151px 1529px #97979761, 306px 460px #97979761,
    1666px 635px #97979761, 1572px 633px #97979761, 1797px 1076px #97979761,
    1727px 1119px #97979761, 474px 871px #97979761, 1491px 1398px #97979761,
    1301px 868px #97979761, 1632px 454px #97979761, 1611px 466px #97979761,
    498px 1162px #97979761, 796px 269px #97979761, 1988px 747px #97979761,
    502px 12px #97979761, 276px 1260px #97979761, 1536px 974px #97979761,
    956px 656px #97979761, 107px 701px #97979761, 965px 491px #97979761,
    1646px 247px #97979761, 1515px 921px #97979761, 1813px 519px #97979761,
    697px 143px #97979761, 591px 1452px #97979761, 1773px 1411px #97979761,
    1622px 1401px #97979761, 534px 340px #97979761, 1590px 284px #97979761,
    1464px 528px #97979761, 734px 1718px #97979761, 1929px 1683px #97979761,
    656px 480px #97979761, 833px 705px #97979761, 1567px 109px #97979761,
    1055px 330px #97979761, 1943px 1367px #97979761, 1935px 1512px #97979761,
    355px 1024px #97979761, 1894px 218px #97979761, 1703px 1772px #97979761,
    781px 1394px #97979761, 367px 42px #97979761, 100px 690px #97979761,
    1831px 458px #97979761, 1884px 1878px #97979761, 814px 1013px #97979761;
}

#stars3 {
  width: 3px;
  height: 3px;

  background: transparent;
  box-shadow: 1994px 1692px #97979761, 961px 1928px #97979761,
    819px 1320px #97979761, 1899px 52px #97979761, 28px 1937px #97979761,
    157px 88px #97979761, 860px 1909px #97979761, 720px 427px #97979761,
    1319px 1469px #97979761, 677px 960px #97979761, 1265px 1181px #97979761,
    1176px 1361px #97979761, 1957px 753px #97979761, 1674px 1355px #97979761,
    929px 1206px #97979761, 1038px 898px #97979761, 1229px 365px #97979761,
    1085px 51px #97979761, 851px 650px #97979761, 385px 21px #97979761,
    41px 1153px #97979761, 718px 70px #97979761, 1321px 579px #97979761,
    497px 1458px #97979761, 1981px 813px #97979761, 776px 1187px #97979761,
    1345px 31px #97979761, 1571px 400px #97979761, 339px 587px #97979761,
    146px 750px #97979761, 64px 61px #97979761, 307px 981px #97979761,
    388px 849px #97979761, 1398px 936px #97979761, 522px 765px #97979761,
    1146px 350px #97979761, 19px 206px #97979761, 1767px 319px #97979761,
    1203px 425px #97979761, 1917px 1018px #97979761, 91px 1401px #97979761,
    898px 697px #97979761, 1712px 1121px #97979761, 633px 1119px #97979761,
    137px 1266px #97979761, 1006px 931px #97979761, 1644px 1804px #97979761,
    1596px 1057px #97979761, 1298px 543px #97979761, 1505px 996px #97979761,
    1972px 1383px #97979761, 1210px 1497px #97979761, 804px 1840px #97979761,
    913px 1047px #97979761, 187px 1824px #97979761, 1564px 1994px #97979761,
    557px 1737px #97979761, 508px 1609px #97979761, 1259px 1911px #97979761,
    1294px 1381px #97979761, 1845px 1843px #97979761, 667px 1497px #97979761,
    1046px 945px #97979761, 937px 1889px #97979761, 982px 1806px #97979761,
    1293px 671px #97979761, 668px 904px #97979761, 358px 1971px #97979761,
    133px 766px #97979761, 1152px 1573px #97979761, 318px 694px #97979761,
    578px 1184px #97979761, 1620px 1701px #97979761, 1359px 354px #97979761,
    1807px 1367px #97979761, 458px 911px #97979761, 1387px 806px #97979761,
    523px 81px #97979761, 1243px 341px #97979761, 906px 1730px #97979761,
    1629px 474px #97979761, 1690px 419px #97979761, 847px 808px #97979761,
    528px 1627px #97979761, 480px 718px #97979761, 537px 593px #97979761,
    1432px 1333px #97979761, 200px 1228px #97979761, 1800px 1375px #97979761,
    725px 1231px #97979761, 1749px 1432px #97979761, 1948px 303px #97979761,
    1889px 252px #97979761, 1114px 1400px #97979761, 759px 646px #97979761,
    222px 571px #97979761, 1908px 1401px #97979761, 1856px 1204px #97979761,
    338px 1645px #97979761, 1528px 1050px #97979761;
  animation: animStar 150s linear infinite;
}
#stars3:after {
  content: "";
  position: absolute;
  top: 2000px;

  background: transparent;
  box-shadow: 1994px 1692px #97979761, 961px 1928px #97979761,
    819px 1320px #97979761, 1899px 52px #97979761, 28px 1937px #97979761,
    157px 88px #97979761, 860px 1909px #97979761, 720px 427px #97979761,
    1319px 1469px #97979761, 677px 960px #97979761, 1265px 1181px #97979761,
    1176px 1361px #97979761, 1957px 753px #97979761, 1674px 1355px #97979761,
    929px 1206px #97979761, 1038px 898px #97979761, 1229px 365px #97979761,
    1085px 51px #97979761, 851px 650px #97979761, 385px 21px #97979761,
    41px 1153px #97979761, 718px 70px #97979761, 1321px 579px #97979761,
    497px 1458px #97979761, 1981px 813px #97979761, 776px 1187px #97979761,
    1345px 31px #97979761, 1571px 400px #97979761, 339px 587px #97979761,
    146px 750px #97979761, 64px 61px #97979761, 307px 981px #97979761,
    388px 849px #97979761, 1398px 936px #97979761, 522px 765px #97979761,
    1146px 350px #97979761, 19px 206px #97979761, 1767px 319px #97979761,
    1203px 425px #97979761, 1917px 1018px #97979761, 91px 1401px #97979761,
    898px 697px #97979761, 1712px 1121px #97979761, 633px 1119px #97979761,
    137px 1266px #97979761, 1006px 931px #97979761, 1644px 1804px #97979761,
    1596px 1057px #97979761, 1298px 543px #97979761, 1505px 996px #97979761,
    1972px 1383px #97979761, 1210px 1497px #97979761, 804px 1840px #97979761,
    913px 1047px #97979761, 187px 1824px #97979761, 1564px 1994px #97979761,
    557px 1737px #97979761, 508px 1609px #97979761, 1259px 1911px #97979761,
    1294px 1381px #97979761, 1845px 1843px #97979761, 667px 1497px #97979761,
    1046px 945px #97979761, 937px 1889px #97979761, 982px 1806px #97979761,
    1293px 671px #97979761, 668px 904px #97979761, 358px 1971px #97979761,
    133px 766px #97979761, 1152px 1573px #97979761, 318px 694px #97979761,
    578px 1184px #97979761, 1620px 1701px #97979761, 1359px 354px #97979761,
    1807px 1367px #97979761, 458px 911px #97979761, 1387px 806px #97979761,
    523px 81px #97979761, 1243px 341px #97979761, 906px 1730px #97979761,
    1629px 474px #97979761, 1690px 419px #97979761, 847px 808px #97979761,
    528px 1627px #97979761, 480px 718px #97979761, 537px 593px #97979761,
    1432px 1333px #97979761, 200px 1228px #97979761, 1800px 1375px #97979761,
    725px 1231px #97979761, 1749px 1432px #97979761, 1948px 303px #97979761,
    1889px 252px #97979761, 1114px 1400px #97979761, 759px 646px #97979761,
    222px 571px #97979761, 1908px 1401px #97979761, 1856px 1204px #97979761,
    338px 1645px #97979761, 1528px 1050px #97979761;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}

/* Contact Form  */

.ContactForm {
  background-color: #00022d00;
}

.keepInTouch {
  color: aliceblue;
  text-align: center;
  margin: 114px 0 61px 0;
}

.formInput,
.message,
.submit-btn {
  letter-spacing: -0.2px;
  font-size: 16px;
}

form {
  padding: 16px;
  margin: 30px 113px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  
}

.segment {
  text-align: right;
  color: #00022ddb;
  text-shadow: 1px 1px 1px #00022ddb;
  margin-bottom: 25px;
}

.submit-btn,
.formInput,
.message {
  color: aliceblue;
  border: 0;
  outline: 0;
  font-size: 16px;
  border-radius: 16px;
  padding: 16px;
  background-color: #111343;
  resize: none;
}

.formInput,
.message {
  margin: 16px;
  box-shadow: inset 2px 2px 5px #191b4b, inset -5px -5px 10px #1b1d4a;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  appearance: none;
  -webkit-appearance: none;
}
.formInput:focus,
.message:focus {
  box-shadow: inset 1px 1px 2px #00022ddb, inset -1px -1px 2px #00022ddb;
}

.submit-btn {
  color: #61677c;
  margin-top: 30px;
  margin-left: 15px;
  box-shadow: -5px -5px 20px #00022ddb, 5px 5px 20px #00022ddb;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
}

.submit-btn:hover {
  box-shadow: -2px -2px 5px #00022ddb, 2px 2px 5px #00022ddb;
  cursor: url("./images/cursor.svg"), pointer;
}

.submit-btn:active {
  box-shadow: inset 1px 1px 2px #00022ddb, inset -1px -1px 2px #00022ddb;
}

.firstIcon {
  /* font-size: 35px; */
  color: white;
  margin-right: 30px;
}

.formIcon {
  /* font-size: 35px; */
  color: white;
}

.unit {
  color: #61677c;
  font-weight: bold;
  box-shadow: -5px -5px 20px #00022ddb, 5px 5px 20px #00022ddb;
  transition: all 0.2s ease-in-out;
  border-radius: 16px;
  width: 60px;
  height: 60px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 16px;
  font-size: 46px;
  background-color: #00022d00;
  color: white;
  margin-bottom: 30px;
}

.unit:hover {
  box-shadow: -2px -2px 5px #00022ddb, 2px 2px 5px #00022ddb;
  cursor: url("./images/cursor.svg"), pointer;
}

.unit:active {
  box-shadow: inset 1px 1px 2px #00022ddb, inset -1px -1px 2px #00022ddb;
}

.errorMessage {
  font-family: inherit;
  margin-left: 25px;
  color: #ac1201;
}

.successMessage {
  font-family: inherit;
  text-align: center;
  /* margin-left: 200px; */
  color: greenyellow;
}

/* Loader */

.loadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: inherit;
  background-image: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 4s;
  z-index: 6;
  background-color: #010030;
  display: flex;
  flex-direction: column;
}

.loadingScreen__title {
  text-align: center;
  font-size: 5rem;
  color: #3d3b9a;
  margin: 0;
  margin-bottom: 40px;
}

.loadingScreen__description {
  text-align: center;
  font-size: 2rem;
  color: #7d7aec;
  margin: 0 50px;
  margin-bottom: 60px;
}

.loadingScreen__text {
  font-size: 2rem;
  color: #3d3b9a;
  margin: 0 140px;
  margin-bottom: 15px;
  text-align: left;
}

.loadingScreen__button {
  padding: 16px 29px;
  color: white;
  border: none;
  font-size: 25px;
  background-color: transparent;
  display: flex;
  align-items: center;
}

.loadingScreen__eastIcon {
  margin-left: 30px;
}

.loadingScreen__button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.loadingScreen__button:hover {
  cursor: url("./images/cursor.svg"), pointer;
}

.loadingScreen__progress {
  position: relative;
  top: 200px;
  left: 0;
  width: fit-content;

  height: 67px;
  background-color: #3d019b80;
  transition: width 0.4s;
  border-radius: 30px;
  color: white;
}

.loadingScreen__progress:hover {
  background-color: #23246b6b;
}

.loadingScreen__progress__value {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0px;
  background-color: #3d019b80;
  transition: width 0.4s;
  margin: 0 auto;
  border-radius: 30px;
}

.loadingScreen__number {
  color: white;
  text-align: center;
  /* margin-top: 18px; */
  font-size: 28px;
}

.loadingScreen--started {
  opacity: 0;
  pointer-events: none;
}

.loadingScreen--started .loadingScreen__board {
  opacity: 0;
}






@media (min-height:200px)  and (max-height: 900px) 
 {

  .loadingScreen__description {
  font-size: 18px;
    margin-bottom: 22px;

 
}

.loadingScreen__text {
  font-size: 13px;
  margin: 4px 98px;
}



.loadingScreen__progress {
  top: 10px;
 
}

.keepInTouch {
  margin-top: -56px;
  font-size: 14px;

}

form {
  padding: 4px;
  margin: 10px;
    margin-top: -24px;

}
.formInput, .message {
  margin: 5px 5px;

  width: 95%;
 
}

.segment {
  margin-right: 23px;
  margin-bottom: 4px;
}

.submit-btn {
  margin-top: -2px;
  margin-left: 5px;
 
}


}
@media (min-width:200px)  and (max-width: 900px) 
 {.object {
width: 30px;
height: 99px;
}

.object__img {
  width: 100px;
  height: 100px;
 
}

.wave {
  width: 138px;
  height: 138px;
  bottom: 129px;
  right: 13px;
}

.object__intro-title {
  font-size: 24px;

}}

@media (min-width:280px)  and (max-width: 480px) 
 {
  .loadingScreen__description {
  font-size: 16px;
 
}

.loadingScreen__text {
  font-size: 13px;
  margin: 13px 22px;
}



.loadingScreen__progress {
   top: 63px;

 
}

.loadingScreen__button {
  padding: 16px 23px;
  font-size: 19px;
}





.keepInTouch {
  margin-top: 37px;
  font-size: 9px;

}

form {
  padding: 4px;
  margin: 10px;
    margin-top: -24px;

}
.formInput, .message {
   margin: 12px 5px;
  width: 95%;
  margin-left: 15px;
 
}

.segment {
  
  margin-right: 40px;
    margin-bottom: 0;

}

.submit-btn {
    margin-top: 11px;
  margin-left: 6px;
}


}





@media only screen 
  and (min-width: 481px) 
  and (max-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 


.loadingScreen__description {
  font-size: 24px;

}

.loadingScreen__text {
  font-size: 19px;
  margin: 0 56px;
  margin-bottom: 22px;
}

.loadingScreen__progress {
  position: relative;
  top: 15px;
  left: 0;
 
}



.keepInTouch {
    margin-top: 86px;
  font-size: 14px;

}

form {
  padding: 4px;
  margin: 10px;
    margin-top: -24px;

}
.formInput, .message {
  margin: 16px 5px;
  width: 95%;

 
}

.segment {
  
  margin-right: 12px;
    margin-bottom: 0;

}

.errorMessage {

  font-size: 12px;
}

.submit-btn {
  margin-top: 30px;
  margin-left: 15px;
}

     }







